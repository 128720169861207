import React from 'react';
import './App.css'; // Make sure the CSS file is correctly linked

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <h1>Green Jungle</h1>
                <nav>
                    <a href="#services">Services</a>
                    <a href="#about">About Us</a>
                    <a href="#contact">Contact</a>
                </nav>
            </header>
            <main className="App-main">
                <div className="center-container">
                    <section id="services" className="section">
                        <h2>Services</h2>
                        <p>
                            At Green Jungle, we specialize in creating custom web applications that are not only
                            innovative but also scalable and secure. Our team works closely with clients to understand
                            their unique requirements, enabling us to deliver tailored solutions that drive business
                            success. Whether you're looking to build a dynamic website, an e-commerce platform, or a
                            complex web-based application, we have the expertise to bring your vision to life.
                        </p>
                        <p>
                            Our commitment to excellence extends to our UI/UX design services, where we prioritize user
                            experience and interface design. By focusing on the end-user, we ensure that our projects
                            are not only aesthetically pleasing but also intuitive and user-friendly. We employ the
                            latest design trends and best practices to create engaging experiences that captivate and
                            retain users. Our design process involves thorough research and testing to ensure that the
                            final product is accessible, easy to use, and meets the highest standards of quality.
                        </p>
                        <p>
                            Beyond development and design, Green Jungle offers ongoing support and maintenance to ensure
                            that your web applications remain up-to-date and perform at their best. Our support services
                            include regular updates, security patches, performance optimizations, and troubleshooting to
                            address any issues that may arise. We understand the importance of reliability in today's
                            digital landscape, which is why we are committed to providing our clients with the support
                            they need to ensure their online presence is strong, secure, and scalable.
                        </p>
                    </section>


                    <section id="about" className="section">
                        <h2>About Us</h2>
                        <p>
                            Founded in 2020, Green Jungle has established itself as a leader in the field of web
                            application development. With a relentless focus on innovation and quality, we have
                            successfully delivered cutting-edge solutions to businesses across industries. Our journey
                            began with a simple mission: to harness the power of the internet to provide effective
                            solutions that drive growth and efficiency for our clients. Today, we continue to push the
                            boundaries of what's possible, leveraging the latest technologies to deliver exceptional
                            results.
                        </p>
                        <p>
                            At the core of Green Jungle are our values: innovation, collaboration, and integrity. We
                            believe in constantly exploring new ideas and technologies to provide our clients with the
                            best possible solutions. Collaboration is key to our success; we work closely with our
                            clients, listening to their needs and feedback, to ensure our projects exceed expectations.
                            Integrity guides our business practices; we strive for transparency, honesty, and ethical
                            behavior in all our interactions, ensuring we build lasting relationships based on trust.
                        </p>
                        <p>
                            Our team is our greatest asset. Comprised of talented developers, designers, and project
                            managers, we bring a wealth of experience and a diverse skill set to every project. Each
                            team member is dedicated to continuous learning and professional growth, ensuring we remain
                            at the forefront of web development trends and technologies. This dedication to excellence
                            and innovation is what enables us to tackle any challenge and deliver solutions that are not
                            only effective but also sustainable and scalable.
                        </p>
                    </section>


                    <section id="contact" className="section">
                        <h2>Contact</h2>
                        <p>Have a project in mind? Let's talk and turn your idea into a reality.</p>
                    </section>
                </div>
            </main>
            <footer className="App-footer">
                <p>© 2024 Green Jungle, All rights reserved.</p>
            </footer>
        </div>
    );
}

export default App;
